// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import './bootstrap_custom'
import '../stylesheets/application'
import './toastr'

require("trix")
require("@rails/actiontext")
import 'bootstrap4-datetimepicker'


$(document).on('turbolinks:load', function () {
    $('.datetimepicker').datetimepicker({
        locale: 'ru',
        format: 'DD.MM.YYYY',
        icons: {
            previous: 'fas fa-arrow-left',
            next: 'fas fa-arrow-right',
            time: 'far fa-clock',
            up: 'fas fa-arrow-up',
            down: 'fas fa-arrow-down',
            date: 'far fa-calendar'

        }
    });
});